import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/43787b3448e31a7ba2b1e3bdeaf43a70586d14e8130278efca1c90964d3ef984/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0],
})
export class SharedExtensionsModule {}
